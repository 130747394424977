import React from "react"
import { Link, graphql } from "gatsby"
import SEOHeader from "../components/seo-header"
import Layout from "../components/layout"
import HeaderBanner from "../components/headerbanner"
import "../styles/style.scss"
import "../styles/blog.scss"
import Img from "gatsby-image"
import BlogSearchComponent from "../components/search/blogsearch"

import BlogCard from "../components/blog/blog-card"
export default function BlogSearch(props) {
  const params = new URLSearchParams(props.location.search)
  const query = params.get("query") // bar

  const { data } = props
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const category = Object.values(data.category.distinct)
  const { currentPage, blognumPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === blognumPages
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  // posts.map(post => {
  //   console.log("Format", post.node.frontmatter.title.includes("Chennai"))
  // })

  const Category = category.map(cate => {
    return (
      <li>
        <Link
          to={
            "/blog/category/" +
            cate
              .replace(/&/g, "")
              .toLowerCase()
              .replace("  ", "-")
              .replace(" ", "-")
          }
        >
          {cate}
        </Link>
      </li>
    )
  })

  return (
    <div>
      <SEOHeader
        title="Gofloaters Blog"
        description="Gofloaters Blog - GoFloaters is a platform that helps you find workspaces that can be booked instantly."
        socialURL="https://assets.gofloaters.com/socialimage/homepage.jpg"
        pinterest="true"
      ></SEOHeader>
      <Layout location={props.location} title={siteTitle}>
        <HeaderBanner>
          <h1>Search : {query}</h1>
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <BlogSearchComponent />
              </div>
            </div>
          </div>
        </HeaderBanner>

        <div className="container">
          <div className="row">
            {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug
              if (
                node.frontmatter.title.includes(query) ||
                node.fields.slug.includes(query) ||
                node.html.includes(query)
              ) {
                return (
                  <div className="col-md-6">
                    <br></br>
                    <div className="BlogCard" key={node.fields.slug}>
                      <Link to={"/blog" + node.fields.slug}>
                        <h4 className="BlogTitle" title={title}>
                          {title}
                        </h4>
                        <div>
                          <Img
                            fluid={
                              node.frontmatter.featuredImage.childImageSharp
                                .fluid
                            }
                            style={{ borderRadius: "16px" }}
                            className="img-responsive"
                            title={title}
                            alt={title}
                          />
                        </div>
                        <br></br>
                        <p className="blogDate">
                          By {node.frontmatter.author} {" / "}
                          {node.timeToRead} mins read
                        </p>
                        <p
                          className="BlogDesc"
                          dangerouslySetInnerHTML={{ __html: node.excerpt }}
                        />
                        <br></br>
                        <Link
                          className="claimnowButton"
                          to={"/blog" + node.fields.slug}
                        >
                          Read More
                        </Link>
                        <br></br>
                      </Link>
                      <br></br>
                    </div>
                  </div>
                )
              } else {
                return ""
              }
            })}
          </div>
        </div>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  query blogSearchPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    category: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" } }
        fileAbsolutePath: { regex: "/(blog)/" }
      }
    ) {
      distinct(field: frontmatter___category)
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/(blog)/" }
        frontmatter: { templateKey: { eq: "blog-post" } }
      }
    ) {
      edges {
        node {
          html
          excerpt
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date 
            title
            author
            featuredImage {
              relativeDirectory
              publicURL
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
